.searchContainer {
    position: absolute;
    top: 1rem;
    left: 1rem;
    margin: 0 auto;
    width: 260px;
    height: 40px;
    z-index: 1050;
}

[data-reach-combobox], [data-reach-combobox-input] {
    width: 100%;
    height: 100%;
}