.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-sm){
    padding: 0 !important;
}

.modal-plus2{
    z-index: 1052 !important;
}

.ant-input-affix-wrapper > input.ant-input:not(.ant-input-sm) {
    padding: 6px 11px !important;
}

.ant-input-affix-wrapper > .ant-input-suffix {
    padding-right: 11px !important;
    padding-left: 4px !important;
    margin-left: 0 !important;
    transition: background-color 0.4s !important;
}

.ant-input-group-addon:first-child {
    background-color: #ebedef !important;
    border-radius: 0.25rem !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix{
    padding-right: 32px !important;
}

.modal.show.big-transform .modal-dialog{
    transform: translateX(calc(256px/2))
}

.c-sidebar-minimized + .c-wrapper .modal.show .modal-dialog{
    transform: translateX(calc(56px/2)) !important;
}


.ant-input-affix-wrapper > input:-webkit-autofill + .ant-input-suffix,
input:-webkit-autofill:hover + .ant-input-suffix, 
input:-webkit-autofill:focus + .ant-input-suffix, 
input:-webkit-autofill:active + .ant-input-suffix,
input:-internal-autofill-selected + .ant-input-suffix,
input:-internal-autofill-selected:hover + .ant-input-suffix,
input:-internal-autofill-selected:focus + .ant-input-suffix,
input:-internal-autofill-selected:active + .ant-input-suffix {
    background-color: #E8F0FE !important;
}

button, button:focus{
    outline: none !important;
}

.btn-link:focus, .btn-link.focus{
    box-shadow: none !important;
}

.btn-link.no-dec:hover, .btn-link.hover.no-dec{
    text-decoration: none !important;
}

.btn-link{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.btn.fake-btn:focus, .btn.fake-btn.focus {
    box-shadow: unset !important;
    outline: none !important;
    border-color: none !important;
}

p, span, h1, h2, h3, h4, h5, h6 {
    white-space: pre-line !important;
}

.skip-lines {
    white-space: unset !important;
}

.row{
    margin-right: 0 !important;
    margin-left: 0 !important;
}

div[class^='col-'], div[class*=' col-'], div.col{
    // padding-left: 0 !important;
    // padding-right: 0 !important;
}

.react-tel-input{
    flex: 1 !important;
    //display: flex !important;
}

.react-tel-input input{
    border-radius: 0.25rem !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    width: 100% !important;
    padding: 0.375rem 0.75rem !important;
    padding-left: 54px !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    border: 1px solid !important;
    color: #768192 !important;
    background-color: #fff !important;
    border-color: #d8dbe0 !important;
}

.react-tel-input input.invalid-number{
    border-color: #e55353 !important;
}

.react-tel-input input:focus.invalid-number{
    box-shadow: 0 0 0 0.2rem rgb(229 83 83 / 25%) !important;
}

.react-tel-input .country-list .search{
    padding: 10px 6px !important;
}

.react-tel-input .country-list .search-box{
    margin: 0 !important;
    border-radius: 0.25rem !important;
    padding-left: 0.75rem !important;
}

.badge:empty{
    display: inline-block !important;
    padding: 0.4em !important;
}

.custom-control-input {
    height: unset !important;
}

.custom-control-label::before, .custom-control-label::after {
    top: 0 !important;
    bottom: 0 !important;
    margin: auto !important;
}

.c-icon{
    min-width: 1rem !important;
}

.c-icon.md {
    min-width: 1.5rem !important;
    height: 1.5rem !important;
    font-size: 1.5rem !important;
}

.c-icon.bg {
    min-width: 2rem !important;
    height: 2rem !important;
    font-size: 2rem !important;
}

.card-body.overflow-hidden div.table-responsive {
    overflow-x: hidden !important;
}

.text-decoration-line-through{
    text-decoration: line-through !important;
}

[data-popper-placement="bottom-end"].dropdown-menu.show{
    left: -105px !important;
    top: 37px !important;
    transform: initial !important;
}

[role="tooltip"].tippy-box .tippy-content {
    white-space: pre-line !important;
}

.ant-dropdown-menu {
    min-width: 10rem !important;
    padding: 0.5rem 0 !important;
    font-size: 0.875rem !important;
    text-align: start !important;
    list-style: none !important;
    background-clip: padding-box !important;
    border: 1px solid !important;
    border-radius: 0.25rem !important;
    color: #3c4b64 !important;
    background-color: #fff !important;
    border-color: #d8dbe0 !important;
    li.ant-dropdown-menu-item{
        display: flex !important;
        align-items: center !important;
        width: 100% !important;
        padding: 0.5rem 1.25rem !important;
        clear: both !important;
        font-weight: 400 !important;
        text-align: inherit !important;
        white-space: nowrap !important;
        background-color: transparent;
        border: 0 !important;
        color: #4f5d73 !important;
        &:hover {
            background-color: #EBEDEF;
        }
    }
}

.ant-select-selection-search{
    margin-inline-start: 0 !important;
}

.ant-select.form-control {
    line-height: 1.5 !important;
    color: #768192 !important;
    font-size: 0.875rem !important;
    padding: 0 !important;
    .ant-select-selector {
        align-items: center !important;
        position: relative !important;
        border: none !important;
        flex-wrap: unset;
        padding: 0.375rem 0.75rem !important;
        max-height: 100% !important;
        .ant-select-selection-overflow, .ant-select-selection-overflow * {
            // height: 100% !important;
            // min-height: 24px !important;
            .ant-select-selection-item-content {
                // line-height: 125% !important;
            }
        }
        .ant-select-selection-placeholder {
            left: 0 !important;
            right: 0 !important;
            padding-left: inherit !important;
            padding-right: inherit !important;
            top: unset !important;
            transform: unset !important;
            line-height: 1.5 !important;
            position: absolute !important;
        }
        .ant-select-selection-search .ant-select-selection-search-input {
            height: 100% !important;
        }
        .ant-select-selection-item {
            line-height: inherit !important;
        }
    }
    .ant-select-clear .anticon {
        vertical-align: top !important;
    }
}

.c-app.c-default-layout .c-sidebar.c-sidebar-fixed{
    z-index: 2040 !important;
}

.dropdown{
    height: fit-content !important;
}

.badge {
    width: fit-content !important;
    max-width: 100% !important;
    > span{
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100% !important;
        line-height: 1.25 !important;
    }
}

input.DateInput_input.DateInput_input_1 {
    padding: 4px 11px 4px !important;
}

body > tr.ant-table-row.row-dragging {
    border-color: grey;
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;

    td {
        position: relative;
        padding: 16px 16px;
        overflow-wrap: break-word;
        border-bottom: 1px solid #f0f0f0;
        background: #fafafa;
        transition: background 0.3s;
    }
}

button.border-rad-unset {
    border-radius: unset;
}

button.rounded-end {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

button.rounded-start {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;    
}

[data-value="__EMPTY__"].ant-cascader-menu-item.ant-cascader-menu-item-disabled .ant-cascader-checkbox.ant-cascader-checkbox-disabled {
    display: none !important
}

.col-xxl, .col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1{
    flex-wrap: wrap;
}

.cursor-pointer {
    cursor: pointer !important;
}

div.DateRangePicker_picker {
    z-index: 10 !important;
}

.upload-dragger-wrapper > span{
    height: fit-content !important;
}

.col-xxl, .col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    transition: all 0.3s ease-out !important;
    transition-property: width, max-width !important;
}

.line-clamp {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

reach-portal div[data-state="suggesting"]{
   z-index: 1050 !important;   
}

.ant-form-item-has-feedback .ant-form-item-children-icon {
    margin-top: -13px !important;
}

.ant-form-item.ant-form-item-has-feedback {
    margin-bottom: 12px !important;
}

.ant-table-row-expand-icon::before, .ant-table-row-expand-icon::after {
    position: absolute;
    background: currentColor;
    transition: transform 0.3s ease-out;
    content: '';
}

.ant-table-row-expand-icon::after {
    top: 3px;
    bottom: 3px;
    left: 7px;
    width: 1px;
    transform: rotate(90deg);
}

.ant-table-row-expand-icon::before {
    top: 7px;
    right: 3px;
    left: 3px;
    height: 1px;
}

.ant-table-row-expand-icon-collapsed::before {
    transform: rotate(-180deg);
}
.ant-table-row-expand-icon-collapsed::after {
    transform: rotate(0deg);
}

.ant-input-group.ant-input-group-compact {
    display: flex !important;
    button.btn {
        display: inline-block !important;
        max-height: 36px !important;
    }
    .ant-row.ant-form-item {
        flex: 1 !important;
    }
}

.ant-form-item.ant-form-item-has-feedback .ant-input-group-addon:last-child {
    padding-right: calc(calc(11px / 4) + 32px) !important;
}

.ant-form-item.ant-form-item-has-feedback .ant-input-group-addon .c-icon {
   color: inherit !important;
}

.ant-menu.ant-menu-root .ant-row.ant-form-item {
    margin-bottom: 0px !important;
}

.cs-mw-80{
    max-width: 80% !important;
}

._loading_overlay_wrapper {
    width: 100% !important;
    height: 100% !important;
}

._loading_overlay_overlay{
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1028;
}

.hide-overlay ._loading_overlay_overlay {
    background-color: rgba(0, 0, 0, 0) !important;
}

#dropdown-menu-nav-profile{
    transform: translate(-45%, 8%) !important;
    width: 260px !important;
    max-width: 260px !important;
}