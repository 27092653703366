.awesomeSlider {
    --slider-height-percentage: 50%;
    --slider-transition-duration: 315ms;
    --organic-arrow-thickness: 8px;
    --organic-arrow-border-radius: 1px;
    --organic-arrow-height: 22px;
    --organic-arrow-color: #fff;
    --control-button-width: 15%;
    --control-button-height: 70%;
    --control-button-background: transparent;
    --control-bullet-color: #fff;
    --control-bullet-active-color: #fff;
    --loader-bar-color: #fff;
    --loader-bar-height: 6px;
}

.captionContainer {
    position: absolute;
    max-width: fit-content;
    border-radius: 0.5rem;
    color: #fff;
    background-color: rgba(50, 31, 219, .8);
    bottom: 10%;
    padding: 0.5em 1em;
    font-size: 85%;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    right: 0;
    left: 0;
    margin: auto;
}   